import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./InfoModal.css";

function InfoModal(props) {
  return (
    <Modal
      className="info-modal"
      centered
      show={props.shouldShow}
      onHide={props.closeCallback}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="info-header">Behind the cig science</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Cigair.city represents your city's air quality in terms of it's impact
        on your health. In general, a value of 22 pm2.5 pollutant is equivalent
        to smoking one cigarette.
      </Modal.Body>
      <Modal.Footer>
        <p>Data acquired from waqi.info</p>
      </Modal.Footer>
    </Modal>
  );
}

export default InfoModal;
