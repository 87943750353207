import React, { useState, useEffect } from "react";
import "./App.css";
import { Form, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import Results from "./Results.js";
import Div100vh from "react-div-100vh";
import InfoModal from "./InfoModal";
import { animateScroll as scroll } from "react-scroll";
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import InputField from "./InputField";
import InputField2 from "./InputField2";

function App() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [airQuality, setAirQuality] = useState(null);
  const [location, setLocation] = useState(null);
  const [showModal, setModal] = useState(false);
  mapboxgl.accessToken = 'pk.eyJ1IjoicXVpbm5xYmIiLCJhIjoiY2wxdG4xb2doMGUwdjNpbnQ3NGhjZ3Q5ZyJ9.2eSCClRVDMOpGPH2hIop_A';

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl
  });

  function inlineAPI(cityName, latitude, longitude) {
    setFetchLoading(true);
    let url =
      "https://api.waqi.info/feed/geo:" +
      latitude +
      ";" +
      longitude +
      "/?token=" +
      "97a6de1eb7fabfdf02b7bd8c0c282fe9c4ee1b8a";

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        if (data.data !== "Unknown station") {
          fetchSuccess(data);
        } else {
          fetchSuccess(null);
        }
      })
      .catch((error) => {
        fetchSuccess(null);
      });
  }

  function fetchSuccess(newData) {
    setAirQuality(newData);
    setFetchLoading(false);
    setTimeout(function () {
      scroll.scrollToBottom();
    }, 400);
  }

  function handleCityChange(placeSuggestion) {
    document.activeElement.blur();
    let cityName = placeSuggestion.text;
    let lat = placeSuggestion.center[1];
    let lng = placeSuggestion.center[0];
    setLocation(cityName);
    setAirQuality(null);
    inlineAPI(cityName, lat, lng);
  }


  function getLoader() {
    if (fetchLoading) {
      return (
        <div className="loader">
          <p>Performing Air Quality Sampling...</p>
          <ReactLoading
            className="spinner"
            color={"gray"}
            type={"spin"}
            height={"20%"}
            width={"20%"}
          />
        </div>
      );
    }
  }

  function displayModal() {
    setModal(true);
    console.log("should be showing modal");
  }

  function hideModal() {
    setModal(false);
    console.log("should be hiding modal");
  }

  return (
    <div className="App">
      <InfoModal shouldShow={showModal} closeCallback={hideModal} />
      <Div100vh className="App-header">
        <Button
          className="about-button"
          onClick={displayModal}
          variant="secondary"
        >
          How it works
        </Button>
        <span className="form-header">
          <h1>
            <span className="bold">Cig</span>air.city
          </h1>
        </span>
        <Form>
          <Form.Row>
            <Form.Group>
              <InputField2
                onLocationSelected={handleCityChange}
              />
            </Form.Group>
          </Form.Row>
        </Form>
        <hr></hr>
        <div>{getLoader()}</div>
      </Div100vh>
      <Results location={location} airQuality={airQuality} />
    </div>
  );
}

export default App;
