import React from "react";
import { useState } from "react";
import "./InputField.css";
import { Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";


function InputField2(props) {
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const accessToken = 'pk.eyJ1IjoicXVpbm5xYmIiLCJhIjoiY2wxdG4xb2doMGUwdjNpbnQ3NGhjZ3Q5ZyJ9.2eSCClRVDMOpGPH2hIop_A';

  function handleLocationSelected(suggestion) {
    if(props.onLocationSelected) {
      props.onLocationSelected(suggestion);
    }
    setValue(suggestion.place_name);
    setSuggestions([]);
  }

  const handleChange = async (event) => {
    setValue(event.target.value);
    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/\
        ${event.target.value}.json?\
access_token=${accessToken}\
&types=place\
&autocomplete=true\
&limit=3`;
      const response = await fetch(endpoint);
      const results = await response.json();
      setSuggestions(results.features);
    } catch (error) {
      console.log("Error fetching data, ", error);
    }
  return {
    value,
  };
}

    const handleClear = async () => {
        setValue("");
        setSuggestions([]);
    }

  return (
    <div id="form-holder">
      <div id="input-box">
      <Form.Control
        autocomplete="off"
        id="location-input-field"
        className="my-search"
        placeholder="Enter your city"
        onChange={handleChange}
        value={value}
        />
        <button className="search-clear" type="button" onClick={() => {
                  handleClear();
                }}><FontAwesomeIcon className="orange" icon={faWindowClose} />
        </button>
        </div>
      {suggestions != null && suggestions.length > 0 && (
        <div className="suggestions-div">
          {suggestions.map((suggestion, index) => {
            return (
              <p
                key={index}
                onClick={() => {
                  handleLocationSelected(suggestion);
                }}
              >
                {suggestion.place_name}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

/*const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);
  const accessToken = 'pk.eyJ1IjoicXVpbm5xYmIiLCJhIjoiY2wxdG4xb2doMGUwdjNpbnQ3NGhjZ3Q5ZyJ9.2eSCClRVDMOpGPH2hIop_A';

  const handleChange = async (event) => {
    setValue(event.target.value);

    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/\
        ${event.target.value}.json?\
access_token=${accessToken}\
&types=place\
&autocomplete=true\
&limit=3`;
      const response = await fetch(endpoint);
      const results = await response.json();
      setSuggestions(results.features);
    } catch (error) {
      console.log("Error fetching data, ", error);
    }
  };
  return {
    value,
    onChange: handleChange,
    setValue,
    suggestions,
    setSuggestions
  };
};
*/

export default InputField2;
