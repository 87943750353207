import React, { useEffect, useState } from "react";
import "./Results.css";
import { Row, Col, Button } from "react-bootstrap";
import { animateScroll as scroll } from "react-scroll";
import { faMapMarkerAlt, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Div100vh from "react-div-100vh";
import html2canvas from "html2canvas";
import DetailsModal from "./DetailsModal";

const Background = require("./tallblurred10.jpg");
const goodCaptions = ["Clean lungs!", "Breathe easy!", "Not bad!"];

const badCaptions = ["Dirty lungs!", "Cough...", "Yikes!", "Stay inside!"];
let currentHeight;
let oldArgs;
function Results(args) {
  const [showDetails, setDetails] = useState(false);

  window.onresize = function() {
    let newHeight = window.innerHeight;
    if (newHeight > currentHeight) {
      scroll.scrollToBottom();
    }
    currentHeight = newHeight;
  };

  function generateImages(count) {
    let pad = "non";
    let noGuts = false;
    let shrink = "non";
    if (window.innerWidth < 480 && count > 6) {
      if (count > 28) {
        count = 28;
      }
      if (count > 14) {
        shrink = "shrink";
      }
      noGuts = true;
      pad = " addpad";
    }
    let bigClass = "bigcig " + shrink + pad;
    let smallClass = "smallcig " + shrink + pad;
    let imageContainer = [];
    let rounded_count = Math.floor(count);
    if (count >= 1) {
      for (let i = 0; i < rounded_count; i++) {
        imageContainer.push(
          <Col key={"img" + i.toString()}>
            <img src="bigcig.png" alt="cigarette" className={bigClass} />
          </Col>
        );
      }
    }
    if (!Number.isInteger(count)) {
      imageContainer.push(
        <Col key="final">
          <img src="smallcig.png" alt="cigarette" className={smallClass} />
        </Col>
      );
    }
    let container = (
      <div className="image-container">
        <Row noGutters={noGuts} className="img-row">
          {imageContainer}
        </Row>
      </div>
    );
    return container;
  }

  function generateCaption(count) {
    let tagline = "";
    let captionClass = "caption";
    let countClass = "orange underline count";
    if (count > 10) {
      captionClass = "caption-small";
      countClass = "orange underline count-small";
    }
    if (count > 2) {
      tagline = badCaptions[getRandom(badCaptions.length)] + " You'll smoke ";
    } else {
      if (count === 0) {
        tagline = "WOOHOO! You didn't smoke any cigarettes today!";
      }
      tagline = goodCaptions[getRandom(goodCaptions.length)] + " You'll smoke ";
    }
    return (
      <h1 className={captionClass}>
        {tagline} <span className={countClass}>{count}</span> cigarettes today!
      </h1>
    );
  }

  function calculateCount(data) {
    if (data.data !== "Unknown station") {
      let value;
      let rounded;
      value = data.data.aqi / 22;
      rounded = Number(value.toFixed(1));
      return rounded;
    } else {
      console.log("can't find location");
    }
  }

  function getRandom(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  function handleClick() {
    scroll.scrollToTop();
  }

  function displayModal() {
    setDetails(true);
  }

  function hideModal() {
    setDetails(false);
  }

  useEffect(() => {
    currentHeight = window.innerHeight;
  }, []);

  if (args.airQuality != null) {
    const background = {
      backgroundImage: `url(${Background})`
    };

    let count = calculateCount(args.airQuality);
    return (
      <Div100vh id="canvas" className="results">
        <h1 className="location">
          <FontAwesomeIcon className="orange" icon={faMapMarkerAlt} />{" "}
          {args.location}
        </h1>
        <h3 className="cigair-label">
          <span className="orange">cigair</span>.city
        </h3>
        <div className="center-stuff">
          <div className="caption-container">{generateCaption(count)}</div>
          <div className="cigarette-container">{generateImages(count)}</div>
        </div>
        <Button className="footer" variant="primary" onClick={displayModal}>
          Details
        </Button>
        <Button
          className="search-again"
          variant="secondary"
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faUndoAlt} />
        </Button>
        <a className="grayanchor" href="http://www.freepik.com">
          Designed by gstudioimagen / Freepik
        </a>
        <DetailsModal
          location={args.location}
          data={args.airQuality}
          shouldShow={showDetails}
          closeCallback={hideModal}
        />
      </Div100vh>
    );
  } else {
    const background = {
      backgroundImage: `url(${Background})`,
      display: "contents"
    };
    return <div className="results" style={{ ...background }}></div>;
  }
}

export default Results;
