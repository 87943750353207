import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Container, Table } from "react-bootstrap";
import "./DetailsModal.css";

function DetailsModal(props) {
  let aqData = props.data.data;
  let loc = props.location;
  console.log(aqData);
  return (
    <Modal
      className="details-modal"
      centered
      show={props.shouldShow}
      onHide={props.closeCallback}
    >
      <Modal.Header className="details-header" closeButton>
        <Modal.Title>
          <span className="orange">{loc}</span>
          <span> air quality</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="show-grid">
            <p>
              <span className="detail-title">Air Quality Index: </span>
              {aqData.aqi}
            </p>
          </Row>

          <Row className="show-grid">
            <p>
              <span className="detail-title">Dominant Pollutant: </span>
              {aqData.dominentpol}
            </p>
          </Row>
          <hr></hr>
          <h4 style={{ color: "gray" }}>Air Quality Scale:</h4>
          <Table responsive>
            <thead>
              <th>Index</th>
              <th>Pollution Level</th>
            </thead>
            <tbody>
              <tr>
                <td>0-50</td>
                <td>Good!</td>
              </tr>
              <tr>
                <td>51-100</td>
                <td>Moderate</td>
              </tr>
              <tr>
                <td>101-150</td>
                <td>Unhealthy if sensitive</td>
              </tr>
              <tr>
                <td>151-200</td>
                <td>Unhealthy</td>
              </tr>
              <tr>
                <td>201-300</td>
                <td>Just hold your breath</td>
              </tr>
              <tr>
                <td>300+</td>
                <td>Seriously!?</td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default DetailsModal;
